<style>
.order{
    height: 100%;
}
</style>

<template>
    <div class="order">
        <v-chart :option="list()" :init-options="initOptions" ref="order" autoresize/>
    </div>
</template>

<script>
export default {
    name: 'order',
    data() {
        return {
            initOptions: {
                renderer: 'canvas'
            },
            nameData: [],
            timeData: [],
            contentData: [],
            listData: [],
            len: 36,
            count1: 0,
            count2: 4,
            seriesdata: [],
            familyNames: ["赵", "钱", "孙", "李", "周", "吴", "郑", "王", "冯", "陈", 
                        "褚", "卫", "蒋", "沈", "韩", "杨", "朱", "秦", "尤", "许",
                        "何", "吕", "施", "张", "孔", "曹", "严", "华", "金", "魏", 
                        "陶", "姜", "戚", "谢", "邹", "喻", "柏", "水", "窦", "章",
                        "云", "苏", "潘", "葛", "奚", "范", "彭", "郎", "鲁", "韦", 
                        "昌", "马", "苗", "凤", "花", "方", "俞", "任", "袁", "柳",
                        "酆", "鲍", "史", "唐", "费", "廉", "岑", "薛", "雷", "贺", 
                        "倪", "汤", "滕", "殷", "罗", "毕", "郝", "邬", "安", "常",
                        "乐", "于", "时", "傅", "皮", "卞", "齐", "康", "伍", "余", 
                        "元", "卜", "顾", "孟", "平", "黄", "和", "穆", "萧", "尹"],
            lsatNames: ["小姐","先生","女士","先生"],
            prefixArray: ["130", "131", "132", "133", "135", "137", "138", "170", "187", "189"],
            firstword: [
                '成功订购{0}瓶成洛舒活液',
                '成为第{0}位VIP会员',
                '预约了脊柱梳理套餐',
                '预约了脊柱侧弯调理套餐',
                '预约了四肢疼痛调理套餐',
                '预约了肌骨体态精调套餐',
                '预约了筋膜刀套餐',
                '预约了富贵包调理套餐',
                '预约了全脊柱梳理套餐',
                '预约了寰枢椎调理套餐',
                '预约了骨盆调理套餐',
                '预约了脊柱梳理套餐',
                '预约了轰脂增肌套餐',
                '购买了脊柱侧弯调理套餐',
                '购买了四肢疼痛调理套餐',
                '购买了肌骨体态精调套餐',
                '购买了筋膜刀套餐',
                '购买了富贵包调理套餐',
                '购买了全脊柱梳理套餐',
                '购买了寰枢椎调理套餐',
                '购买了骨盆调理套餐',
                '购买了轰脂增肌套餐',
                '通过培训，成功获得国家注册高级执业理疗师',
                // '晋升成为第{0}位铂金代理',
                // '成功提现{0}元',
                // '晋升成为第{0}位钻石代理',
                '成功订购{0}个SeeRoo护颈枕'
            ]
        }
    },
    methods: {
        list() {
            let that = this
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    top:'0%',
                    left: '0%',
                    right: '0%',
                    bottom: '-5%',
                    containLabel: true
                },
                xAxis: {
                    show: false,
                    type: 'value'
                },
                yAxis: [{ 
                    type: 'category',
                    position:'right',
                    inverse: true,
                    axisLine:{position:'right'},
                    show: false,
                    data: ['', '', '', '','']
                    }],
                series: that.seriesdata
            }
        },
        dealdata() {
            let that = this
            for (let i = 1; i < this.len; i++) {
                this.nameData.push({
                    name: that.randname(),
                    value: 4
                })
                this.timeData.push({
                    name: that.randtel(),
                    value: 4
                })
                this.contentData.push({
                    name: that.randword(),
                    value: 12
                })
            }
        },
        setSeriesdata() {
            let that = this
            this.seriesdata = [ {
                    name: '列表名',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: function(e) {
                                // console.log(e);
                                return '{cirle|' + e.name + '}';
                            },
                            rich: {
                                cirle: {
                                    color: "#fff",
                                    // width:100,
                                    // height:40,
                                    // backgroundColor:"red",
                                    // align:"center",
                                }
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: "rgb(172,63,36)"
                        }
                    },
                    data: ([{ name: "用户", value: 4,  itemStyle: {
                        normal: {
                            color: "#61D89F"
                        }
                    },}]).concat(that.nameData.slice(0, 10))
                }, {
                    name: '时长',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: function(e) {
                                // console.log(e);
                                return '{cirle|' + e.name + '}';
                            },
                            rich: {
                                cirle: {
                                    color: "#fff",
                                    // width:100,
                                    // height:40,
                                    // backgroundColor:"red",
                                    // align:"center",
                                }
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: "rgb(172,63,36)"
                        }
                    },
                    data: ([{ name: "联系方式", value: 4,  itemStyle: {
                        normal: {
                            color: "#61D89F"
                        }
                    },}]).concat(that.timeData.slice(0, 10))
                }, {
                    name: '列表内容',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: function(e) {
                                // console.log(e);
                                let len=e.name.length;
                                if(len>40){
                                    e.name=e.name.substring(0,40)+"\n"+e.name.substring(40,len);
                                }
                                return '{cirle|' + e.name + '}';
                            },
                            rich: {
                                cirle: {
                                    color: "#fff",
                                    width: 100,
                                    // height:40,
                                    // backgroundColor:"red",
                                    align: "center",
                                }
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: "rgb(172,63,36)"
                        }
                    },
                    data:  ([{ name: "详细信息", value: 12,  itemStyle: {
                        normal: {
                            color: "#61D89F"
                        }
                    },}]).concat(that.contentData.slice(0, 10))
                }]
        },
        loopdata() {
            let that = this
            setInterval(function() {
                that.count1++
                that.count2++
                that.count1 = that.count1>(that.len-5) ? 0 : that.count1
                that.count2 = that.count2>(that.len-1)? 4 : that.count2
                // that.seriesdata[0].data =([{name: "",value: 1,  itemStyle: {
                //     normal: {
                //         color: "#000"
                //     }
                // },}]).concat(that.listData.slice(that.count1, that.count2))
                that.seriesdata[0].data =([{ name: "用户", value: 4,  itemStyle: {
                    normal: {
                        color: "#61D89F"
                    }
                },}]).concat(that.nameData.slice(that.count1, that.count2))
                that.seriesdata[1].data = ([{name:"联系方式",value:4,  itemStyle: {
                    normal: {
                        color: "#61D89F"
                    }
                },}]).concat(that.timeData.slice(that.count1, that.count2))
                that.seriesdata[2].data =([{name:"详细信息",value:12,  itemStyle: {
                    normal: {
                        color: "#61D89F"
                    }
                },}]).concat(that.contentData.slice(that.count1, that.count2))
            },parseInt(Math.random()*(3000-1000+1)+1000))
        },
        randname() {
            let i = Math.floor(Math.random()*100)
            let x = Math.floor(Math.random()*4)
            return this.familyNames[i] + this.lsatNames[x]
        },
        randtel() {
            let i = Math.floor(Math.random()*10)
            let prefix = ""
            for (var j = 0; j < 4; j++) {
                prefix = prefix + Math.floor(Math.random() * 10);
            }
            return this.prefixArray[i]+"****"+prefix
        },
        randword() {
            String.prototype.format = function () {
                // 数据长度为空，则直接返回
                if (arguments.length == 0){
                    return this;
                }
            
                // 使用正则表达式，循环替换占位符数据
                for (var result = this, i = 0; i < arguments.length; i++){
                    result = result.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i]);
                    return result;
                }
            }
            let i = Math.floor(Math.random() * this.firstword.length)
            if(i == 0){
                return this.firstword[i].format(parseInt(Math.random()*100+1))
            }else if(i == 1){
                return this.firstword[i].format(parseInt(Math.random()*(20000-5000+1)+5000))
            }else if(i == 23){
                return this.firstword[i].format(parseInt(Math.random()*5)+1)
            }else{
                return this.firstword[i]
            }
        }
    },
    mounted() {
        this.dealdata()
        this.setSeriesdata()
        this.list()
        this.loopdata()
    }
}
</script>