import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import "echarts"
import * as echarts from "echarts/core";
import ECharts from 'vue-echarts'
import 'element-ui/lib/theme-chalk/index.css'
import chinaMap from './assets/china.json'
import { Row, Col, Container, Header, Aside, Main, Footer } from 'element-ui'
import 'echarts-gl'
import _ from 'lodash'
import store from './store/'

Vue.prototype._ = _
// Vue.prototype.$moment = moment;
Vue.prototype.$store = store

Vue.use(Row);
Vue.use(Col);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Vuex);

const { registerMap } = echarts;
registerMap('china', chinaMap)

Vue.component('v-chart', ECharts)

Vue.config.productionTip = false
Vue.prototype.$echarts = ECharts
// Vue.prototype.$monent = monent
new Vue({

  render: h => h(App),
  store,
}).$mount('#app')
