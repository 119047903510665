import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

const ResetCustomData = 'resetCustomData'
const SetTotalCustom = 'SetTotalCustom'

const state = {
    //loading动画
    cityCustom: [51894, 17893, 16212, 25267, 24110, 19324, 9098],
    totalCustom: 0
}

const mutations = {
    [ResetCustomData](state, playload) {
        state.cityCustom.splice(playload.i , 1 ,playload.mun)
    },
    [SetTotalCustom](state, mun) {
        state.totalCustom = mun
    }
}

const actions = {
    updateCustomData({commit, state}) {
        let rang = 0
        let upper = _.random(0,3)
        do {
            let param = {}
            param.i = _.random(6)
            // console.log(param.i)
            
            // let mun = coumtArr[i]
            //    console.log(this.option.series[0].data[i])
            switch (param.i) {
                case 0:
                    param.mun = state.cityCustom[param.i] + _.random(10)
                    break;
                case 3:
                    param.mun = state.cityCustom[param.i] + _.random(5)
                    break;
                default:
                    param.mun = state.cityCustom[param.i] + _.random(2)
                    break;
            }
            rang++
            commit(ResetCustomData, param)
        } while (rang<=upper);
    },
    countTotalCustom({commit, state}){
        var len = state.cityCustom.length
        var count = 0
        for(var i = 0; i < len; i++) {
            count += state.cityCustom[i]
        }
        commit(SetTotalCustom, count)
    }
}

export default new Vuex.Store({
    state,
    mutations,
    actions
})