<style>
.vip{
    height: 40%;
    width: 100%;
}
</style>

<template>
    <div class="vip">
        <v-chart :option="option" :init-options="initOptions" ref="vip" autoresize />
    </div>
</template>

<script>
// import * as echarts from "echarts/core";
// import 'echarts-liquidfill'
import 'echarts-liquidfill'
import { isOpen } from '@/utils/index'

var attrs = [{
    'name': '总用户数',
    'value': 129798,
    'center_l': '16%'
}, {
    'name': '会员',
    'value': '25.15%',
    'center_l': '50%'
}, {
    'name': '非会员',
    'value': '74.85%',
    'center_l': '84%'
}]
var datas = [
    [0.9, 0.9, 0.9],
    [0.3, 0.3, 0.3],
    [0.6, 0.6, 0.6]
];


var series = [];
for (var i = 0; i < datas.length; i++) {
    var item = {
        type: 'liquidFill',
        radius: '45%',
        center: [attrs[i].center_l, '55%'],
        //  shape: 'roundRect',
        data: datas[i],
        backgroundStyle: {
            color: {
                type: 'linear',
                x: 1,
                y: 0,
                x2: 0.5,
                y2: 1,
                colorStops: [{
                    offset: 1,
                    color: 'rgba(4,33,77, 0)'
                }, {
                    offset: 0.5,
                    color: 'rgba(4,33,77, .5)'
                }, {
                    offset: 0,
                    color: 'rgba(4,33,77, 1)'
                }],
                globalCoord: false
            },
        },
        outline: {
            borderDistance: 5,
            itemStyle: {
                borderWidth: 5,
                borderColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(50,115,233, 1)'
                    }, {
                        offset: 0.5,
                        color: 'rgba(50,115,233, .5)'
                    }, {
                        offset: 1,
                        color: 'rgba(50,115,233, 1)'
                    }],
                    globalCoord: false
                },
                shadowBlur: 10,
                shadowColor: '#000',
            }
        },
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [{
                offset: 1,
                color: 'rgba(72,107,254, 0.8)'
            }, {
                offset: 0.75,
                color: 'rgba(21,216,238, .5)'
            }, {
                offset: 0,
                color: 'rgba(72,107,254, 1)'
            }],
            globalCoord: false
        },
        label: {
            normal: { //此处没有生效，本地生效
                textStyle: {
                    fontSize: 10,
                    color: '#e6e6e6',
                },
                formatter: ''

            }
        },
        itemStyle: {
            // bottom: '5px',
            opacity: 0.5,
            shadowBlur: '50',
            shadowColor: "rgba(0, 0, 0, 0.4)",
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [{
                    offset: 1,
                    color: 'rgba(72,107,254, 0.8)'
                }, {
                    offset: 0.75,
                    color: 'rgba(21,216,238, .5)'
                }, {
                    offset: 0,
                    color: 'rgba(72,107,254, 1)'
                }],
                globalCoord: false
            },
        }
    }
    series.push(item);
}

export default {
    name: 'Vip',
    data() {
        // let that = this
        return {
            initOptions: {
                renderer: 'canvas'
            },
            option: {
            title: {
                    text: '顾客类型分布',
                    subtext: 'Customer type distribution',
                    left: '20px',
                    padding: [
                        5,  // 上
                        0, // 右
                        5,  // 下
                        0, // 左
                    ],
                    y: 10,
                    textStyle: {
                        color: '#fff',
                        fontSize: 14
                    }
                },
        graphic: [{
                type: 'group',
                left: '7%',
                top: '52.5%',
                children: [{
                    type: 'text',
                    z: 100,
                    left: 'center',
                    top: '10',
                    style: {
                        fill: '#fff',
                        text: attrs[0].value,
                        font: '16px Microsoft YaHei'
                    }
                },{
                    type: 'text',
                    z: 100,
                    left: 'center',
                    top: '85',
                    style: {
                        fill: '#fff',
                        text: attrs[0].name,
                        font: '16px Microsoft YaHei'
                    }
                }]
            },{
                type: 'group',
                left: '44.5%',
                top: '52.5%',
                children: [{
                    type: 'text',
                    z: 100,
                    left: 'center',
                    top: '10',
                    style: {
                        fill: '#fff',
                        text: attrs[1].value,
                        font: '16px Microsoft YaHei'
                    }
                },{
                    type: 'text', 
                    left: 'center',
                    top: '85',
                    style: {
                        fill: '#fff',
                        text: attrs[1].name,
                        font: '16px Microsoft YaHei'
                    }
                }]
            },{
                type: 'group',
                left: '77.5%',
                top: '52.5%',
                children: [{
                    type: 'text',
                    z: 100,
                    left: 'center',
                    top: '10',
                    style: {
                        fill: '#fff',
                        text: attrs[2].value,
                        font: '16px Microsoft YaHei'
                    }
                },{
                    type: 'text',
                    z: 100,
                    left: 'center',
                    top: '85',
                    style: {
                        fill: '#fff',
                        text: attrs[2].name,
                        font: '16px Microsoft YaHei'
                    }
                }]
            }],
            series: series
            }
        }
    },
    mounted() {
       let that = this
       that.$store.dispatch('countTotalCustom')
       setInterval(()=>{
           if(isOpen()) {
               that.$store.dispatch('countTotalCustom')
               that.option.graphic[0].children[0].style.text = that.$store.state.totalCustom
           }
       }, 4500)
       setInterval(()=>{
        //    if(isOpen()) {
        //        that.$store.dispatch('countTotalCustom')
        //        that.option.graphic[0].children[0].style.text = that.$store.state.totalCustom
        //    }
        let vip = (that._.random(25.51, 25.99)).toFixed(2);
        let unvip = (that._.subtract(100, vip)).toFixed(2);
        that.option.graphic[1].children[0].style.text = vip + '%'
        that.option.graphic[2].children[0].style.text = unvip + '%'
       }, 5000)
    },
}
</script>
