<style>
.book{
    height: 20%;
}
</style>

<template>
    <div class="book">
        <v-chart :option="option" :init-options="initOptions" ref="book" autoresize/>
    </div>
</template>

<script>
import { isOpen } from '@/utils/index'
import * as echarts from "echarts/core";
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 

var placeHolderStyle = {
    normal: {
        label: {
            show: false
        },
        labelLine: {
            show: false
        },
        color: "rgba(0,0,0,0)",
        borderWidth: 0
    },
    emphasis: {
        color: "rgba(0,0,0,0)",
        borderWidth: 0
    }
};


// var dataStyle = {
//     formatter: '0人',
//     position: 'center',
//     show: true,
//     textStyle: {
//         fontSize: '12',
//         fontWeight: 'normal',
//         color: '#AAAFC8'
//     }
// };
export default {
    name: "Book",
    data() {
        return {
            initOptions: {
                renderer: 'canvas'
            },
            option: {
                // backgroundColor: '#031245',
                title: [{
                text: '已预约',
                left: '14%',
                top: '68%',
                textAlign: 'center',
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: '14',
                    color: '#fff',
                    textAlign: 'center',
                },
            }, {
                text: '服务中',
                left: '49%',
                top: '68%',
                textAlign: 'center',
                textStyle: {
                    color: '#fff',
                    fontWeight: 'normal',
                    fontSize: '14',
                    textAlign: 'center',
                },
            }, {
                text: '服务完',
                left: '84%',
                top: '68%',
                textAlign: 'center',
                textStyle: {
                    color: '#fff',
                    fontWeight: 'normal',
                    fontSize: '14',
                    textAlign: 'center',
                },
            }],

            //第一个图表
            series: [{
                    type: 'pie',
                    // hoverAnimation: false, //鼠标经过的特效
                    radius: ['70%', '75%'],
                    center: ['15%', '50%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center',
                            show: false
                        }
                    },
                    data: [{
                            value: 100,
                            itemStyle: {
                                normal: {
                                    color: ['rgba(176, 212, 251, 0.3)']
                                }
                            },
                        }, {
                            value: 35,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },
                //上层环形配置
                {
                    type: 'pie',
                    hoverAnimation: false, //鼠标经过的特效
                    radius: ['70%', '75%'],
                    center: ['15%', '50%'],
                    startAngle: 225,
                    zlevel: 9,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center'
                        }
                    },
                    data: [{
                            value: 75,
                            "itemStyle": {
                                "color": new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        "offset": 0,
                                        "color": '#00cefc'
                                    }, {
                                        "offset": 1,
                                        "color": '#367bec'
                                    }])
                            },
                            label: {
                                formatter: '0人',
                                position: 'center',
                                show: true,
                                textStyle: {
                                    fontSize: '12',
                                    fontWeight: 'normal',
                                    color: '#AAAFC8'
                                }
                            },
                        }, {
                            value: 35,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },


                //第二个图表
                {
                    type: 'pie',
                    // hoverAnimation: false,
                    radius: ['70%', '75%'],
                    center: ['50%', '50%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center',
                            show: false
                        }
                    },
                    data: [{
                            value: 100,
                            itemStyle: {
                                normal: {
                                    color: ['rgba(176, 212, 251, 0.3)']
                                }
                            },

                        }, {
                            value: 35,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },

                //上层环形配置
                {
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ['70%', '75%'],
                    center: ['50%', '50%'],
                    startAngle: 225,
                    zlevel: 9,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center',
                            show: false
                        }
                    },
                    data: [{
                            value: 30,
                            "itemStyle": {
                                "color": new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        "offset": 0,
                                        "color": '#9FE6B8'
                                    }, {
                                        "offset": 1,
                                        "color": '#32C5E9'
                                    }])
                            },
                            label: {
                                formatter: '0人',
                                position: 'center',
                                show: true,
                                textStyle: {
                                    fontSize: '12',
                                    fontWeight: 'normal',
                                    color: '#AAAFC8'
                                }
                            },
                        }, {
                            value: 55,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },
                //第三个图表
                {
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ['70%', '75%'],
                    center: ['85%', '50%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center',
                            show: false
                        }
                    },
                    data: [{
                            value: 100,
                            itemStyle: {
                                normal: {
                                    color: ['rgba(176, 212, 251, 0.3)']
                                }
                            },

                        }, {
                            value: 35,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },

                //上层环形配置
                {
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ['70%', '75%'],
                    center: ['85%', '50%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center',
                            show: false
                        }
                    },
                    data: [{
                            value: 65,
                            "itemStyle": {
                                "normal": {
                                    "color": new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        "offset": 0,
                                        "color": '#FDFF5C'
                                    }, {
                                        "offset": 1,
                                        "color": '#FFDB5C'
                                    }]),
                                }
                            },
                            label: {
                                formatter: '0人',
                                position: 'center',
                                show: true,
                                textStyle: {
                                    fontSize: '12',
                                    fontWeight: 'normal',
                                    color: '#AAAFC8'
                                }
                            },
                        }, {
                            value: 55,
                            itemStyle: placeHolderStyle,
                        },

                    ]
                },
            ]
            }
        }
    },
    mounted() {
        let totalpeople = this._.random(560,640)
        this.option.series[1].data[0].value = this._.ceil(this._.divide(totalpeople,700)*100)
        this.option.series[1].data[0].label.formatter = totalpeople+'人'
        let that = this
        setInterval(()=>{
            if(isOpen()){
                let serving = that._.random(56,70)
                that.option.series[3].data[0].value = that._.ceil(this._.divide(serving,100)*100)
                that.option.series[3].data[0].label.formatter = serving+'人'
            }
        },3890)
        // console.log(1)
        let opentime = moment({hour: 11, minute: 15});
        // let endtime = this.$moment({hour: 21, minute: 30});
        
        // this.option.series[5].data[0].value = this._.ceil(this._.divide(over,350)*100)
        setInterval(()=>{
            if(isOpen()){
                let nowdate = moment();
                let seconds = this._.ceil(this._.divide(moment.duration(nowdate.diff(opentime)).asSeconds(), 5),0);
                let over = that._.ceil(that._.multiply(0.04, seconds))
                // over = that._.add(over,that._.random(0.0401,0.0410))
                that.option.series[5].data[0].value = this._.ceil(this._.divide(over,700)*100)
                that.option.series[5].data[0].label.formatter = that._.ceil(over)+'人'
            }
        },4980)
    },
}
</script>
