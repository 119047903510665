<style>
.inner-custom{
    height: 54.5%;
    padding-top: 20px;
    /* padding-left: 2px;
    border-radius: 50px; */
    padding-right: 10px;
}
</style>

<template>
    <div class="inner-custom">
        <v-chart :option="options()" :init-options="initOptions" ref="custom" autoresize  class="v-custom"/>
    </div>
</template>

<script>
// import moment from 'moment'
import { isOpen } from '@/utils/index'

export default {
   name: 'Custom' ,
   data() {
       return {
           initOptions: {
                renderer: 'canvas'
            },
           option: {
               title: {
                    text: '各地区顾客总数统计',
                    subtext: 'Statistics of total number of customers in each province',
                    left: '20px',
                    padding: [
                        5,  // 上
                        0, // 右
                        5,  // 下
                        0, // 左
                    ],
                    y: 10,
                    textStyle: {
                        color: '#fff',
                        fontSize: 16
                    }
                },
                // backgroundColor: "#404a5947",
                grid: {
                    left: 40,
                    top: 55,
                    bottom: 10
                },
                tooltip: {
                    trigger: 'item',
                    textStyle: {
                        fontSize: 16
                    },
                    formatter: '{b0}:{c0}'
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear',
                xAxis: {
                    // max: 'dataMax',
                    inverse: true,
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: true
                    },
                    // offset: 20
                },
                yAxis: [{
                        type: 'category',
                        inverse: true,
                        data: [
                            '广州',
                            '东莞',
                            '深圳',
                            '长沙',
                            '武汉',
                            '江苏',
                            '郑州'
                        ],
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            margin: 0,
                            textStyle: {
                                color: '#fff',
                                fontSize: 14
                            }
                        },
                        animationDuration: 300,
                        animationDurationUpdate: 300,
                        max: 6,
                        realtimeSort: true,
                    },

                ],
                series: [{
                    type: 'pictorialBar',
                    symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAADYElEQVR4nO2dz0sUYRjHP7tIdAmxQ1LdlhCKMohAIsgiyEuHjkUEFQTlpejS/xCCBB06RBGBBKIG4cGyH0qHBKE9eKyFqBQPRQeNCt06vGNY7bq7szPfeZLnAwuzM+/zgw/DDvMu70wOIVveLscJOwycA44A24CfwAfgKXAbeFVvovlC/o/vuVwuTj+x0FWiYdGbgXvA8RrjHgAXgIVaCbMU3SKr1BhtwEtgZx1jTwI7gG7ga5pNNUO+9pBMuEN9klfYD9xMqZdEsCj6AHAiRtxZYFeyrSSHRdGnYsblCD8jJrEoek8TsbsT6yJhLIrelFFsqlgUPZtRbKpYFP2kidjxxLpIGIuiB4AvMeLmgJGEe0kMi6I/AVdjxPVSx91hVlgUDXAXuEaY16jFMnAJeJhqR01iVTTAdeAYUFxjzBRwCLgl6agJrM51rDAO7AP2EmbxthPO8vfAc2Ams84axLpoCGKLrH1mm8eC6KPAGaAL2Fpj7AZgY7T9DfhRY/wc4eflPmH+OjOynI8uEGbpukXlJ4Dz84V8aWWHcj46q4thFzCNTjJRren2UrlLWPM3WYjuAMYIk/tq2oCx9lK5Q11YLboFGARaxXVX0woMtpfK0uuTWvRFoFNcsxKdhF5kqEX3iuuthbQXtehG/gdMG2kvlm/B1xUuWoSLFmFF9CRwg2TnM4pRzskEc8bGiugR4ArhNjkpJqKcJv51sSJ63eOiRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEWvTHKvs/p1izWu5qvaSCWvTlCvtmgeEUaw5TeUVtpV5SQy16COgBRoHXhMWb3aS7PnAhqjEQ1RwFeuYL+aEUa/5DFmtYHkefOEwQVmcBvKD+FQNvgNN/P+pHiV8MRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEixbhokVYEx3nudGKXE1jTfS6xUWLcNEiXLQIFy3CRYtw0SJctAgXLcJFi3DRIv430eUq2+axJvp7jePPqmzHySXFmuhHwFKVYzNA/6rv/VR/s9BSlMsM1kTPEN4DPkU4I8vAO6APOAgsrhq7GO3ri8aUo5ipKIep1zv9AtipgOACGIrLAAAAAElFTkSuQmCC',
                    symbolRepeat: true,
                    symbolMargin: '5%',
                    symbolClip: 'true',
                    symbolSize: 20,
                    symbolPosition: 'start',
                    // barWidth: '90%',
                    realtimeSort: true,
                    // seriesLayoutBy: 'column',
                    name: 'X',      
                    label: {
                        show: true,
                        position: 'insideRight',
                        offset: [40, 2.5],
                        color: '#fff',
                        formatter: function(params) {
                            return params.value;
                        },
                        valueAnimation: true
                    },
                    symbolBoundingData: 10000,
                    markArea: {
                        animation: true
                    },
                    data: [51894, 17893, 16212, 25267, 24110, 19324, 9098]
                },
                ]
           }
       }
   },
   methods: {
       updateData() {
        //    let day = this.$moment().day()
        //    let coumtArr = []
        //    coumtArr = coumtArr.concat(this.option.series[0].data)
        //    console.log(coumtArr)
           if(isOpen()) {
               this.$store.dispatch('updateCustomData')
           }
           this.option.series[0].data = this.$store.state.cityCustom
       },
       options() {
           return this.option
       }
   },
   watch: {
    //    option(nval) {
    //        console.log(nval)
    //    }
   },
   mounted() {
       let that = this
       setInterval(()=>{
           that.updateData()
        //    console.log(111)
       }, 3000)
       this.options()
   },
   destroyed() {

   }
}
</script>
