<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <div class="page">
      <el-row class="row1">
        <el-col :span="6" style="height:100%;">
          <Custom></Custom>
          <Vip></Vip>
        </el-col>
        <el-col :span="12" class="map">
          <h1 class="main-title" style="height:5%;">脊动力&脊医卫全国门店运营监测系统</h1>
          <Map></Map>
        </el-col>
        <el-col :span="6" style="height:100%;">
          <el-row style="height:30%;">
            <el-col :span="12"  style="height:100%;">
              <DayMoney></DayMoney>
            </el-col>
            <el-col :span="12"  style="height:100%;">
              <DalyMoney></DalyMoney>
            </el-col>
          </el-row>
          <Book></Book>
          <Flow></Flow>
        </el-col>
      </el-row>
      <el-row class="row2">
        <el-col :span='6'  style="height:100%;">
          <Rate></Rate>
        </el-col>
        <el-col :span='12' style="height:100%;">
          <Order></Order>
        </el-col>
        <el-col :span='6' style="height:100%;">
          <Comment></Comment>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Map from './components/Map.vue'
import Custom from './components/Custom.vue'
import Vip from './components/Vip.vue'
import DalyMoney from './components/DalyMoney.vue'
import DayMoney from './components/DayMoney.vue'
import Book from './components/Book.vue'
import Flow from './components/Flow.vue'
import Rate from './components/Rate.vue'
import Order from './components/Order.vue'
import Comment from './components/Comment.vue'

export default {
  name: 'App',
  components: {
    Map,
    Custom,
    Vip,
    DalyMoney,
    DayMoney,
    Book,
    Flow,
    Rate,
    Order,
    Comment
  }
}
</script>

<style>
body{
  margin: 0;
  /* padding: 0; */
}
#app {
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    background: url('../src/assets/images/5a2a47889d03d_03.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 0;
    /* 比例：就是设置paddig-bottom */
    padding-bottom: 56.25%;
}
.page {
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
  }
.row1{
  height: 70%;
}
.row2{
  height: 30%;
}
.map{
  height: 100%;
  /* width: 100%; */
  /* border-radius: 10%; */
  position: relative;
}
.main-title{
  width: 100%;
  position: absolute;
  z-index: 1000;
}
.custom{
  height: 50%;
  width: 100%;
}
</style>
