<template>
    <div class="comment">
        <v-chart :option="option" :init-options="initOptions" ref="comment" autoresize/>
    </div>    
</template>

<script>
// import { isOpen } from '@/utils/index'

var plantCap = [{
                    name: '肩颈疼痛',
                    value: '10000+'
                }, {
                    name: '腰椎疼痛',
                    value: '3115'
                }, {
                    name: '胸闷',
                    value: '7963'
                }, {
                    name: '肠胃不好',
                    value: '1698'
                }, {
                    name: '脊柱侧弯',
                    value: '6592'
                }, {
                    name: 'X型腿',
                    value: '8087'
                }, {
                    name: 'O型腿',
                    value: '4987'
                }, {
                    name: '假胯宽',
                    value: '9946'
                }, {
                    name: '关节疼痛',
                    value: '3223'
                }, {
                    name: '增肌减脂',
                    value: '8574'
                }, {
                    name: '含胸驼背',
                    value: '3194'
                }, {
                    name: '扁平足',
                    value: '2589'
                }, {
                    name: '长短腿',
                    value: '89463'
                }, {
                    name: '骨盆前倾',
                    value: '5678'
                }, {
                    name: '骨盆后倾',
                    value: '7416'
                }, {
                    name: '放松肌肉',
                    value: '4356'
                }
                ],
            datalist = [{
                offset: [56, 48],
                symbolSize: 120,
                opacity: .95,
                color: '#f467ce'
            }, {
                offset: [40, 65],
                symbolSize: 55,
                opacity: .88,
                color: '#7aabe2'
            }, {
                offset: [20, 43],
                symbolSize: 90,
                opacity: .84,
                color: '#ff7123'
            }, {
                offset: [83, 30],
                symbolSize: 90,
                opacity: .8,
                color: '#ffc400'
            }, {
                offset: [38, 22],
                symbolSize: 85,
                opacity: .75,
                color: '#5e333f'
            }, {
                offset: [64, 10],
                symbolSize: 70,
                opacity: .99,
                color: '#6b3442'
            }, {
                offset: [75, 75],
                symbolSize: 60,
                opacity: .68,
                color: '#8a3647'
            }, {
                offset: [88, 62],
                symbolSize: 50,
                opacity: .6,
                color: '#68333f'
            },{
                offset: [98, 85],
                symbolSize: 110,
                opacity: .65,
                color: '#9ff048'
            },{
                offset: [55, 85],
                symbolSize: 110,
                opacity: .95,
                color: '#FF9900'
            },{
                offset: [20, 75],
                symbolSize: 100,
                opacity: .65,
                color: '#33CC33'
            }
            ,{
                offset: [100, 45],
                symbolSize: 80,
                opacity: .95,
                color: '#FF9966'
            },{
                offset: [90, 8],
                symbolSize: 60,
                opacity: .55,
                color: '#99CC99'
            },{
                offset: [100, 24],
                symbolSize: 60,
                opacity: .95,
                color: '#FF9900'
            },{
                offset: [15, 20],
                symbolSize: 60,
                opacity: .95,
                color: '#FF9900'
            },{
                offset: [25, 5],
                symbolSize: 55,
                opacity: .95,
                color: '#f467ce'
            }
            ],
            datas= []
            // i=[],
            // flag= 0
for (let i = 0; i < plantCap.length; i++) {
    var item = plantCap[i];
    var itemToStyle = datalist[i];
    datas.push({
        name: item.value + '\n' + item.name,
        value: itemToStyle.offset,
        symbolSize: itemToStyle.symbolSize,
        label: {
            textStyle: {
                fontSize: 12
            },
            show: true
        },
        itemStyle: {
            color: itemToStyle.color,
            opacity: itemToStyle.opacity
        },
    })
}

// for (let index = 0; index < array.length; index++) {
//     const element = array[index];
    
// }

export default {
    name: 'Comment',
    data() {
        return {
            initOptions: {
                renderer: 'canvas'
            },
            option: {
                title: {
                    top: 10,
                    text: '顾客主诉分布',
                    // top: '5',
                    left: 5,
                    textStyle: {
                        fontSize: 14,
                        color: '#fff'
                    }
                },
                grid: {
                    show: false,
                    top: '15%',
                    bottom: 10,
                    left: 0
                },
                xAxis: [{
                    gridIndex: 0,
                    type: 'value',
                    show: false,
                    min: 0,
                    max: 100,
                    nameLocation: 'middle',
                    nameGap: 5
                }],
                yAxis: [{
                    gridIndex: 0,
                    min: 0,
                    show: false,
                    max: 100,
                    nameLocation: 'middle',
                    nameGap: 30
                }],
                series: [{
                    type: 'effectScatter',
                    symbol: 'circle',
                    rippleEffect: {
                        scale: 1.2
                    },
                    animation: 'true',
                    symbolSize: 100,
                    label: {
                        show: true,
                        formatter: '{b}',
                        color: '#fff',
                        textStyle: {
                            fontSize: '30'
                        }
                    },
                    itemStyle: {
                        color: '#00acea'
                    },
                    data: datas
                }]
            }
        }
    },
    methods: {
        newArray(arr) {
            var t;
            for (var i = 0; i < arr.length; i++) {
                var rand = parseInt(Math.random() * arr.length);
                t = arr[rand];
                arr[rand] = arr[i];
                arr[i] = t;
            }
            return arr
        }
    },
    mounted() {
        setInterval(()=>{
            let arr = this.newArray(datalist)
            datas=[]
            for (let i = 0; i < arr.length; i++) {
                let item = plantCap[i]
                let itemToStyle = datalist[i]
                datas.push({
                    name: item.value + '\n' + item.name,
                    value: itemToStyle.offset,
                    symbolSize: itemToStyle.symbolSize,
                    label: {
                        textStyle: {
                            fontSize: 12
                        },
                        show: true
                    },
                    itemStyle: {
                        color: itemToStyle.color,
                        opacity: itemToStyle.opacity
                    },
                })
            }
            this.option.series[0].data = datas
        },3500)
    }
}
</script>

<style>
.comment{
    height: 100%;
}
</style>
