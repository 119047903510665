import moment from 'moment'
import 'moment/locale/zh-cn'

export function isOpen() {
    let nowdate = moment();
    let opentime = moment({hour: 11, minute: 15});
    let endtime = moment({hour: 21, minute: 30});
    let today = nowdate.day()
    if(today === 1){
        return false
    }
    if(nowdate.isBefore(opentime)) {
        return false
    }
    if(nowdate.isAfter(endtime)) {
        return false
    }
    return true
}